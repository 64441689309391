@import "./../theme-bootstrap";

///////////////////////////////
/// HERO Helper
/// design debug tool
/// debug div loads via js library - see file ~/genie_base/js/site/hero_helper.js
///////////////////////////////

.hero-helper {
  border: #ccc solid 1px;
  background-size: 10% 10%;
  background-image: linear-gradient(to right, #ccc 1px, transparent 1px),
    linear-gradient(to bottom, #ccc 1px, transparent 1px);
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
  &--large {
  }
  &--5050 {
  }
}

.hero-debug {
  &.hero-large {
    .hero-large__inner {
      border: red solid 2px;
    }
  }
}